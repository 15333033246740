import React, { useState, useEffect } from 'react';
import { collection, doc, getDocs, setDoc, deleteDoc, writeBatch } from '../../firebase-config';
import { auth, db } from '../../firebase-config';
import NavBar from '../NavBar/navBar';
import './Contacs.css';
import Papa from 'papaparse';

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [activeTab, setActiveTab] = useState('clientes');
  const [newContact, setNewContact] = useState({ name: '', phone: '', category: 'clientes' });
  const [showModal, setShowModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [userId, setUserId] = useState(null);
  const [importedContacts, setImportedContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
        const contactsCollection = collection(db, 'users', user.uid, activeTab);
        const contactsSnapshot = await getDocs(contactsCollection);
        const contactsList = contactsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setContacts(contactsList);
      }
    };

    fetchContacts();
  }, [activeTab]);

  

  const handleImportContacts = async (category) => {
    if (userId && importedContacts.length > 0) {
      const batch = writeBatch(db);
      const newContacts = [];

      importedContacts.forEach(contact => {
        if (contact.name && contact.phone) { // Verifica que los datos no estén vacíos
          const contactRef = doc(collection(db, 'users', userId, category));
          batch.set(contactRef, { 
            name: contact.name, 
            phone: contact.phone, 
            category 
          });
          newContacts.push({ id: contactRef.id, name: contact.name, phone: contact.phone });
        } else {
          console.warn("Contacto inválido:", contact); // Advertencia si un contacto es inválido
        }
      });

      await batch.commit();

      console.log("Contactos guardados en Firestore:", newContacts);

      setContacts([...contacts, ...newContacts]);
      setShowImportModal(false);
      setActiveTab(category); // Cambiar a la categoría seleccionada para mostrar los contactos importados
    }
  };

  const renderContacts = () => {
    return contacts.map((contact, index) => (
      <div key={contact.id} className="contact-item">
        <div>
          <strong>{contact.name}</strong>
          <p>{contact.phone}</p>
        </div>
        <div>
          <button onClick={() => handleEditContact(index)}>Editar</button>
          <button onClick={() => handleDeleteContact(contact.id)} className="delete">Eliminar</button>
        </div>
      </div>
    ));
  };

  const handleEditContact = (index) => {
    const contact = contacts[index];
    setEditingIndex(index);
    setNewContact({ name: contact.name, phone: contact.phone, category: activeTab });
    setShowModal(true);
  };

  const handleDeleteContact = async (id) => {
    if (userId) {
      await deleteDoc(doc(db, 'users', userId, activeTab, id));
      setContacts(contacts.filter(contact => contact.id !== id));
    }
  };

  const handleAddOrUpdateContact = async () => {
    if (userId) {
      if (editingIndex !== null) {
        const contactRef = doc(db, 'users', userId, newContact.category, contacts[editingIndex].id);
        await setDoc(contactRef, newContact);
        const updatedContacts = [...contacts];
        updatedContacts[editingIndex] = { id: contactRef.id, ...newContact };
        setContacts(updatedContacts);
      } else {
        const contactRef = doc(collection(db, 'users', userId, newContact.category));
        await setDoc(contactRef, newContact);
        setContacts([...contacts, { id: contactRef.id, ...newContact }]);
      }
      setShowModal(false);
      setNewContact({ name: '', phone: '', category: 'clientes' });
      setEditingIndex(null);
    }
  };

  return (
    <div className="contacts-page">
      <header className="contacts-header">
        <NavBar />
        <nav className="category-tabs">
          <button className={`category-tab ${activeTab === 'clientes' ? 'active' : ''}`} onClick={() => setActiveTab('clientes')}>
            Clientes
          </button>
          <button className={`category-tab ${activeTab === 'vendedores' ? 'active' : ''}`} onClick={() => setActiveTab('vendedores')}>
            Vendedores
          </button>
          <button className={`category-tab ${activeTab === 'proveedores' ? 'active' : ''}`} onClick={() => setActiveTab('proveedores')}>
            Proveedores
          </button>
        </nav>
      </header>

      <div className="contacts-content contacts-list">
        {renderContacts()}
        <button className="add-contact-btn" onClick={() => setShowModal(true)}>+</button>
      </div>

      {showModal && (
        <div className="modal-box">
          <h2>{editingIndex !== null ? 'Editar Contacto' : 'Agregar Contacto'}</h2>
          <label>
            Nombre:
            <input type="text" value={newContact.name} onChange={(e) => setNewContact({ ...newContact, name: e.target.value })} />
          </label>
          <label>
            Teléfono:
            <input type="text" value={newContact.phone} onChange={(e) => setNewContact({ ...newContact, phone: e.target.value })} />
          </label>
          <label>
            Categoría:
            <select value={newContact.category} onChange={(e) => setNewContact({ ...newContact, category: e.target.value })}>
              <option value="clientes">Clientes</option>
              <option value="vendedores">Vendedores</option>
              <option value="proveedores">Proveedores</option>
            </select>
          </label>
          <div className="modal-actions">
            <button onClick={handleAddOrUpdateContact}>{editingIndex !== null ? 'Guardar Cambios' : 'Agregar'}</button>
            <button className="cancel-btn" onClick={() => setShowModal(false)}>Cancelar</button>
          </div>
        </div>
      )}

      {showImportModal && (
        <div className="modal-box">
          <h2>Selecciona la categoría para importar los contactos</h2>
          <div className="modal-actions">
            <button onClick={() => handleImportContacts('clientes')}>Clientes</button>
            <button onClick={() => handleImportContacts('vendedores')}>Vendedores</button>
            <button onClick={() => handleImportContacts('proveedores')}>Proveedores</button>
            <button className="cancel-btn" onClick={() => setShowImportModal(false)}>Cancelar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contacts;
