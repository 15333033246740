import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider } from './components/UserContext';
import PageView from './components/PageView/PageView';
import Login from './components/Login/LoginUser';
import Dashboard from './components/Dashboard/Main';
import CreateBusiness from './components/CreateBusiness/CreateBusiness';
import BusinessOverview from './components/BusinessOverview/BusinessOverview';
import Movements from './components/Movements/Movements';
import ProductsAndServices from './components/ProductsAndServices/ProductsAndServices';
import Statistics from './components/Statistics/Statistics';
import Contacts from './components/Contacts/Contacts';
import NuevaVenta from './components/Ventas/Ventas';
import Gastos from './components/Gastos/Gastos';
import NuevoProductoVenta from './components/NuevoProducto/NuevoProducto.js';
import { getDoc, doc } from 'firebase/firestore';
import { auth, db } from './firebase-config';
import AdBanner from './AdBanner.js';
import './styles.css'
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  const [setRedirectAfterForm] = useState('/dashboard');
  const [hasBusinessData, setHasBusinessData] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = localStorage.getItem('isAuthenticated') === 'true';
      const firstTimeStatus = localStorage.getItem('isFirstTimeLogin') === 'true';
      setIsAuthenticated(authStatus);
      setIsFirstTimeLogin(firstTimeStatus);

      if (authStatus) {
        const user = auth.currentUser;
        if (user) {
          const businessDocRef = doc(db, 'businesses', user.uid);
          const docSnap = await getDoc(businessDocRef);
          setHasBusinessData(docSnap.exists());
        }
      }
    };

    checkAuth();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const handleFirstTimeLogin = () => {
    setIsFirstTimeLogin(true);
    localStorage.setItem('isFirstTimeLogin', 'true');
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setIsFirstTimeLogin(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isFirstTimeLogin');
  };

  const handleFormSubmission = () => {
    setRedirectAfterForm('/business-overview');
  };

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<PageView />} />
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to={hasBusinessData ? '/movimientos' : (isFirstTimeLogin ? '/create-business' : '/dashboard')} />
              ) : (
                <Login onLogin={handleLogin} onFirstTimeLogin={handleFirstTimeLogin} />
              )
            }
          />
          <Route
            path="/dashboard"
            element={isAuthenticated ? <Dashboard onLogout={handleLogout} /> : <Navigate to="/login" />}
          />
          <Route
            path="/create-business"
            element={
              isAuthenticated && !hasBusinessData ? (
                <CreateBusiness onFormSubmit={handleFormSubmission} />
              ) : (
                <Navigate to={hasBusinessData ? '/movimientos' : '/login'} />
              )
            }
          />
          <Route
            path="/business-overview"
            element={isAuthenticated ? <BusinessOverview /> : <Navigate to="/login" />}
          />
          <Route
            path="/movimientos"
            element={isAuthenticated ? <Movements /> : <Navigate to="/login" />}
          />
          <Route
            path="/productos-servicios"
            element={isAuthenticated ? <ProductsAndServices /> : <Navigate to="/login" />}
          />
          <Route
            path="/estadisticas"
            element={isAuthenticated ? <Statistics /> : <Navigate to="/login" />}
          />
          <Route
            path="/contactos"
            element={isAuthenticated ? <Contacts /> : <Navigate to="/login" />}
          />
          {/* 
          <Route
            path="/settings"
            element={isAuthenticated ? <Settings /> : <Navigate to="/login" />}
          />
          */}
          <Route
            path="/ventas"
            element={isAuthenticated ? <NuevaVenta /> : <Navigate to="/login" />}
          />
          <Route
            path="/gastos"
            element={isAuthenticated ? <Gastos /> : <Navigate to="/login" />}
          />
          <Route path="/nuevo-producto" element={<NuevoProductoVenta />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <AdBanner/>
    </UserProvider>
  );
}

export default App;
