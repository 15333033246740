import React, { useState } from 'react';
import {  AiOutlineBarChart, AiOutlineContacts, AiOutlineSetting, AiOutlineHistory } from 'react-icons/ai';
import { FaBox } from 'react-icons/fa';

import { Link } from 'react-router-dom'; // Importa Link para navegación
import './NavBar.css'; // Asegúrate de que el archivo CSS esté en la misma carpeta

const NavBar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  // Cierra el menú si se hace clic fuera de él
  const handleClickOutside = (event) => {
    if (event.target.closest('.sidebar') === null && sidebarOpen) {
      closeSidebar();
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen]);

  return (
    <div>
      {/* Botón para abrir el menú */}
      <button onClick={toggleSidebar} className="toggle-sidebar-button">
        <span className="sidebar-icon">☰</span>
      </button>
      
      {/* Menú deslizante */}
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <button className="close-sidebar-button" onClick={closeSidebar}>
            ×
          </button>
          
        </div>
        <ul className="sidebar-menu">
          <li className="menu-link">
            <Link to="/movimientos">
              <AiOutlineHistory className="menu-icon" />
              <span>Movimientos</span>
            </Link>
          </li>
          <li className="menu-link">
            <Link to="/productos-servicios">
              <FaBox className="menu-icon" />
              <span>Mis Productos y Servicios</span>
            </Link>
          </li>
          <li className="menu-link">
            <Link to="/estadisticas">
              <AiOutlineBarChart className="menu-icon" />
              <span>Estadísticas</span>
            </Link>
          </li>
          <li className="menu-link">
            <Link to="/contactos">
              <AiOutlineContacts className="menu-icon" />
              <span>Contactos</span>
            </Link>
          </li>
          
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
