import React from 'react';
import './CurrencyModal.css';

const currencies = [
  { code: 'AOA', name: 'Kwanza', flag: '🇦🇴' },
  { code: 'ARS', name: 'Peso Argentino', flag: '🇦🇷' },
  { code: 'BOB', name: 'Boliviano', flag: '🇧🇴' },
  { code: 'BRL', name: 'Real Brasileño', flag: '🇧🇷' },
  { code: 'CAD', name: 'Dólar Canadiense', flag: '🇨🇦' },
  { code: 'CLP', name: 'Peso Chileno', flag: '🇨🇱' },
  { code: 'COP', name: 'Peso Colombiano', flag: '🇨🇴' },
  { code: 'CRC', name: 'Colón Costarricense', flag: '🇨🇷' },
  { code: 'CUP', name: 'Peso Cubano', flag: '🇨🇺' },
  { code: 'MLC', name: 'Peso Cubano Convertible', flag: '🇨🇺' },
  { code: 'DOP', name: 'Peso Dominicano', flag: '🇩🇴' },
  { code: 'USD', name: 'Dólar Estadounidense', flag: '🇺🇸' },
  { code: 'EUR', name: 'Euro', flag: '🇪🇺' },
  { code: 'GTQ', name: 'Quetzal', flag: '🇬🇹' },
  { code: 'HNL', name: 'Lempira', flag: '🇭🇳' },
  { code: 'IND', name: 'Rupia India', flag: '🇮🇳' },
  { code: 'JMD', name: 'Dólar Jamaicano', flag: '🇯🇲' },
  { code: 'KES', name: 'Chelín Keniano', flag: '🇰🇪' },
  { code: 'MXN', name: 'Peso Mexicano', flag: '🇲🇽' },
  { code: 'NIO', name: 'Córdoba', flag: '🇳🇮' },
  { code: 'PAB', name: 'Balboa', flag: '🇵🇦' },
  { code: 'PYG', name: 'Guaraní', flag: '🇵🇾' },
  { code: 'PEN', name: 'Sol Peruano', flag: '🇵🇪' },
  { code: 'ZAR', name: 'Rand Sudafricano', flag: '🇿🇦' },
  { code: 'UYU', name: 'Peso Uruguayo', flag: '🇺🇾' },
  { code: 'VES', name: 'Bolívar Venezolano', flag: '🇻🇪' },
];

const CurrencyModal = ({ isOpen, onClose, onCurrencySelect }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Selecciona tu Moneda</h2>
        <ul className="currency-list">
          {currencies.map((currency) => (
            <li
              key={currency.code}
              onClick={() => {
                onCurrencySelect(currency);
                onClose();
              }}
              className="currency-item"
            >
              <span className="currency-flag">{currency.flag}</span>
              <span className="currency-name">{currency.name}</span>
              <span className="currency-code">({currency.code})</span>
            </li>
          ))}
        </ul>
        <button onClick={onClose} className="close-button">Cerrar</button>
      </div>
    </div>
  );
};

export default CurrencyModal;
