import React, { useState } from 'react';
import { TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Typography, MenuItem } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../../firebase-config'; // Asegúrate de que estas configuraciones estén en tu archivo firebase-config.js
import { useNavigate } from 'react-router-dom';
import './NuevoProductoVenta.css'; // Asegúrate de importar el archivo CSS

const NuevoProductoVenta = () => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [unidad, setUnidad] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [openImageDialog, setOpenImageDialog] = useState(false);

    const [nombreProducto, setNombreProducto] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [fechaVencimiento, setFechaVencimiento] = useState('');
    const [precioCosto, setPrecioCosto] = useState('');
    const [precioVenta, setPrecioVenta] = useState('');
    const [subiendo, setSubiendo] = useState(false);

    const unidades = ['ud', 'kg', 'g', 'l', 'ml', 'km', 'm', 'm2', 'm3', 'ft', 'in', 'lb', 'oz', 'mi'];

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            setImagePreview(URL.createObjectURL(file)); // Crear una URL de vista previa
            setOpenImageDialog(false); // Cerrar el diálogo si se selecciona una nueva imagen
        }
    };

    const handleOpenImageDialog = () => {
        if (imagePreview) {
            setOpenImageDialog(true);
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
        setImagePreview('');
        setOpenImageDialog(false);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
    };

    const handleGuardarProducto = async () => {
        setSubiendo(true);
        const user = auth.currentUser;
    
        try {
            let imageUrl = '';
            
            if (image) {
                const imageRef = ref(storage, `products/${user.uid}/${nombreProducto}-${Date.now()}`);
                await uploadBytes(imageRef, image);
                imageUrl = await getDownloadURL(imageRef);
            }
    
            const productoRef = collection(db, 'users', user.uid, 'productosVenta');
            await addDoc(productoRef, {
                nombre: nombreProducto,
                descripcion,
                cantidad: Number(cantidad),
                unidad,
                fechaVencimiento: new Date(fechaVencimiento),
                precioCosto: Number(precioCosto),
                precioVenta: Number(precioVenta),
                imageUrl,
                timestamp: new Date(),
                typeColor: '#00FF00'
            });
    
            alert("Producto guardado exitosamente.");
            setNombreProducto('');
            setDescripcion('');
            setCantidad('');
            setFechaVencimiento('');
            setPrecioCosto('');
            setPrecioVenta('');
            setImage(null);
            setImagePreview('');
            navigate(-1);
        } catch (error) {
            console.error("Error al guardar el producto: ", error);
            alert("Hubo un error al guardar el producto.");
        } finally {
            setSubiendo(false);
        }
    };

    return (
        <div className="container">
            <Typography variant="h4" className="header">Nuevo Producto</Typography>

            <div className="image-container">
                {imagePreview ? (
                    <img
                        src={imagePreview}
                        alt="Vista previa"
                        className="image-preview"
                        onClick={handleOpenImageDialog}
                    />
                ) : (
                    <IconButton component="label" className="icon-button">
                        <ImageIcon fontSize="large" />
                        <input type="file" accept="image/*" onChange={handleImageChange} hidden />
                    </IconButton>
                )}
                <TextField
                    fullWidth
                    label="Nombre del Producto"
                    helperText="No usar emoticones en los nombres para no afectar los datos"
                    value={nombreProducto}
                    onChange={(e) => setNombreProducto(e.target.value)}
                    className="text-field"
                />
            </div>

            <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
                <DialogTitle className="dialog-title">Opciones de Imagen</DialogTitle>
                <DialogActions className="dialog-actions">
                    <Button onClick={handleRemoveImage} color="secondary">
                        Eliminar Imagen
                    </Button>
                    <Button component="label" color="primary">
                        Cargar Nueva Imagen
                        <input type="file" accept="image/*" onChange={handleImageChange} hidden />
                    </Button>
                    <Button onClick={handleCloseImageDialog} color="primary" className="cancel-button">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>

            <TextField
                fullWidth
                margin="normal"
                label="Descripción del Producto"
                multiline
                rows={3}
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                className="text-field"
            />

            <TextField
                fullWidth
                margin="normal"
                label="Cantidad"
                type="number"
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
                className="text-field"
            />

            <Button variant="outlined" className="button" onClick={handleOpenDialog}>
                Seleccionar Unidad: {unidad || 'Seleccionar'}
            </Button>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle className="dialog-title">Seleccionar Unidad de Medida</DialogTitle>
                <DialogContent dividers style={{ maxHeight: '200px' }}>
                    {unidades.map((u, index) => (
                        <MenuItem key={index} onClick={() => { setUnidad(u); handleCloseDialog(); }}>
                            {u}
                        </MenuItem>
                    ))}
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button onClick={handleCloseDialog} color="primary" className="cancel-button">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <TextField
                fullWidth
                margin="normal"
                label="Fecha de Vencimiento"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                value={fechaVencimiento}
                onChange={(e) => setFechaVencimiento(e.target.value)}
                className="text-field"
            />

            <TextField
                fullWidth
                margin="normal"
                label="Precio Unitario de Costo + Impuesto"
                type="number"
                value={precioCosto}
                onChange={(e) => setPrecioCosto(e.target.value)}
                className="text-field"
            />

            <TextField
                fullWidth
                margin="normal"
                label="Precio Unitario de Venta + Impuesto"
                type="number"
                value={precioVenta}
                onChange={(e) => setPrecioVenta(e.target.value)}
                className="text-field"
            />

            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGuardarProducto}
                disabled={subiendo}
                className="button"
            >
                {subiendo ? "Guardando..." : "Guardar"}
            </Button>
        </div>
    );
};

export default NuevoProductoVenta;
