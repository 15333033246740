import React, { useEffect } from 'react';

const AdBanner = () => {
  useEffect(() => {
    // Verifica si el anuncio ya ha sido empujado
    if (typeof window.adsbygoogle !== 'undefined') {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("Error al cargar el anuncio:", e);
      }
    }
  }, []);

  return (
    <div style={{ width: '100%', minHeight: '100px', display: 'block' }}>
      <ins className="adsbygoogle"
        style={{ display: 'block', width: '100%', height: '100px' }} // Asegura un tamaño visible
        data-ad-client="ca-pub-9672080156010975"
        data-ad-slot="your-ad-slot-id"
        data-ad-format="auto"></ins>
    </div>
  );
};

export default AdBanner;
