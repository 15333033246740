import React, { useEffect } from 'react';

const AdBanner = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.onload = () => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <ins className="adsbygoogle"
           style={{ display: 'block', width: '100%', height: '100px' }}
           data-ad-client="ca-pub-9672080156010975"
           data-ad-slot="5048690501"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  );
};

export default AdBanner;
