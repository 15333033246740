import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider, Grid, Modal, List, ListItem, ListItemText, TextField, InputAdornment } from '@mui/material';
import { collection, getDocs, setDoc, doc } from '../../firebase-config';
import { auth, db } from '../../firebase-config';
import { useNavigate } from 'react-router-dom';

const NuevaVenta = () => {
  const [showClientsModal, setShowClientsModal] = useState(false);
  const [showVendorsModal, setShowVendorsModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDebtModal, setShowDebtModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);

  const [clients, setClients] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('Sin seleccionar');
  const [debtAmount, setDebtAmount] = useState(''); // Inicializa como cadena vacía
  const [discount, setDiscount] = useState(''); // Inicializa como cadena vacía
  const [netTotal, setNetTotal] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContacts = async () => {
      const user = auth.currentUser;
      if (user) {
        const clientsCollection = collection(db, 'users', user.uid, 'clientes');
        const clientsSnapshot = await getDocs(clientsCollection);
        const clientsList = clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClients(clientsList);
      }
    };
    fetchContacts();
  }, []);

  useEffect(() => {
    const fetchVendors = async () => {
      const user = auth.currentUser;
      if (user) {
        const vendorsCollection = collection(db, 'users', user.uid, 'vendedores');
        const vendorsSnapshot = await getDocs(vendorsCollection);
        const vendorsList = vendorsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setVendors(vendorsList);
      }
    };
    fetchVendors();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const user = auth.currentUser;
      if (user) {
        const productsCollection = collection(db, 'users', user.uid, 'productosVenta');
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsList);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    calculateNetTotal();
  }, [selectedProducts, discount, debtAmount]);

  const handleSelectClient = (client) => {
    setSelectedClient(client);
    setShowClientsModal(false);
  };

  const handleSelectVendor = (vendor) => {
    setSelectedVendor(vendor);
    setShowVendorsModal(false);
  };

  const handleSelectProduct = (product) => {
    const selectedQuantity = prompt(`¿Cuántos ${product.unidad || 'unidades'} deseas añadir? (Disponibles: ${product.cantidad})`);
    
    if (selectedQuantity && Number(selectedQuantity) > 0 && Number(selectedQuantity) <= product.cantidad) {
      const productWithQuantity = {
        ...product,
        selectedQuantity: Number(selectedQuantity),
      };
      setSelectedProducts(prevProducts => [...prevProducts, productWithQuantity]);
    } else {
      alert("Cantidad no válida o excede el inventario disponible.");
    }
  
    setShowProductsModal(false);
  };

  const calculateNetTotal = () => {
    const baseTotal = selectedProducts.reduce((total, product) => total + product.precioVenta * product.selectedQuantity, 0);
    const discountAmount = (baseTotal * (discount || 0)) / 100; // Manejar descuento como número
    const totalAfterDiscount = baseTotal - discountAmount;
    const total = totalAfterDiscount - (debtAmount || 0); // Manejar deuda como número
    setNetTotal(total);
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    setDiscount(value === "" ? '' : value); // Manejar como cadena vacía cuando no hay valor
  };

  const handleDebtChange = (e) => {
    const value = e.target.value;
    setDebtAmount(value === "" ? '' : value); // Manejar como cadena vacía cuando no hay valor
  };

  const handleAddProductClick = () => {
    setShowProductsModal(true);
  };

  const handleSaveSale = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const saleData = {
          client: selectedClient,
          vendor: selectedVendor,
          products: selectedProducts,
          paymentMethod,
          debtAmount: Number(debtAmount), // Convertir a número
          discount: Number(discount), // Convertir a número
          netTotal,
          date: new Date(),
          typeColor: '#052796' // Establece el color del tipo como azul
        };
    
        await setDoc(doc(db, 'users', user.uid, 'ventas', `${new Date().getTime()}`), saleData);
    
        for (const product of selectedProducts) {
          const productRef = doc(db, 'users', user.uid, 'productosVenta', product.id);
          const newQuantity = product.cantidad - product.selectedQuantity;
    
          if (newQuantity >= 0) {
            await setDoc(productRef, { cantidad: newQuantity }, { merge: true });
          }
        }
      } catch (error) {
        console.error('Error al guardar la venta: ', error);
      }
      navigate(-1); // Redirige a la página de ventas
    }
  };
  

  return (
    <Box
      sx={{
        backgroundColor: '#000',
        color: '#fff',
        height: '90vh',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <Box>
        <Typography variant="h6" gutterBottom>
          Nueva venta
        </Typography>
        <Divider sx={{ backgroundColor: '#444' }} />
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={6}>
            <Typography color="red">Cliente</Typography>
            <Typography
              onClick={() => setShowClientsModal(true)}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {selectedClient ? selectedClient.name : 'Cliente no definido'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="red">Vendedor</Typography>
            <Typography
              onClick={() => setShowVendorsModal(true)}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {selectedVendor ? selectedVendor.name : 'Vendedor no definido'}
            </Typography>
          </Grid>
        </Grid>

        {/* Lista de productos seleccionados */}
        {selectedProducts.length > 0 && (
          <Box sx={{ marginTop: '20px' }}>
            <Typography variant="h6" gutterBottom>
              Productos Seleccionados
            </Typography>
            <List sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #444', borderRadius: '5px' }}>
              {selectedProducts.map((product, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={product.nombre}
                    secondary={`Cantidad: ${product.selectedQuantity} - Total: $${(product.precioVenta * product.selectedQuantity).toFixed(2)}`}
                    primaryTypographyProps={{ sx: { color: '#fff' } }}
                    secondaryTypographyProps={{ sx: { color: 'grey' } }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>

      <Button
        variant="contained"
        onClick={handleAddProductClick}
        sx={{
          backgroundColor: 'green',
          color: '#fff',
          '&:hover': { backgroundColor: '#388e3c' },
          marginTop: '20px'
        }}
      >
        Agregar producto
      </Button>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="red">Forma de pago</Typography>
            <Typography
              onClick={() => setShowPaymentModal(true)}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {paymentMethod}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="red">Monto a pagar</Typography>
            <TextField
              type="number"
              value={debtAmount}
              onChange={handleDebtChange}
              sx={{ input: { color: '#fff' }, width: '100%', borderColor: '#fff', border: '1px solid #fff' }} // Establecer el borde en blanco
              InputProps={{
                sx: { color: '#fff' },
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography color="red">Descuento (%)</Typography>
            <TextField
              type="number"
              value={discount}
              onChange={handleDiscountChange}
              sx={{ input: { color: '#fff' }, width: '100%', borderColor: '#fff', border: '1px solid #fff' }} // Establecer el borde en blanco
            />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ marginTop: '20px' }}>
          Total Neto: ${netTotal.toFixed(2)}
        </Typography>
      </Box>

      <Button
        variant="contained"
        onClick={handleSaveSale}
        sx={{
          backgroundColor: '#3f51b5',
          color: '#fff',
          '&:hover': { backgroundColor: '#303f9f' },
          marginTop: '20px'
        }}
      >
        Guardar venta
      </Button>

      {/* Modal para seleccionar clientes */}
      <Modal open={showClientsModal} onClose={() => setShowClientsModal(false)}>
        <Box sx={{ backgroundColor: '#fff', padding: '20px', margin: '20px', borderRadius: '8px' }}>
          <Typography variant="h6">Seleccionar Cliente</Typography>
          <List>
            {clients.map((client) => (
              <ListItem button key={client.id} onClick={() => handleSelectClient(client)}>
                <ListItemText primary={client.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>

      {/* Modal para seleccionar vendedores */}
      <Modal open={showVendorsModal} onClose={() => setShowVendorsModal(false)}>
        <Box sx={{ backgroundColor: '#fff', padding: '20px', margin: '20px', borderRadius: '8px' }}>
          <Typography variant="h6">Seleccionar Vendedor</Typography>
          <List>
            {vendors.map((vendor) => (
              <ListItem button key={vendor.id} onClick={() => handleSelectVendor(vendor)}>
                <ListItemText primary={vendor.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>

      {/* Modal para seleccionar productos */}
      <Modal open={showProductsModal} onClose={() => setShowProductsModal(false)}>
        <Box sx={{ backgroundColor: '#fff', padding: '20px', margin: '20px', borderRadius: '8px' }}>
          <Typography variant="h6">Seleccionar Producto</Typography>
          <List>
            {products.map((product) => (
              <ListItem button key={product.id} onClick={() => handleSelectProduct(product)}>
                <ListItemText primary={product.nombre} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </Box>
  );
};

export default NuevaVenta;
