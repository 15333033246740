import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { Box, Typography, Button } from '@mui/material';
import AdBanner from '../AdBanner.js';


const Dashboard = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout(); // Llama a la función de cierre de sesión
    navigate('/create-business'); // Redirige al login
  };

  return (
    <Box
      sx={{
        backgroundColor: '#000',
        color: '#fff',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom>
        ¡Ups! La página ha sido recargada.
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        Para no perder tus datos, por favor, vuelve a iniciar sesión.
      </Typography>
      <Button
        variant="contained"
        onClick={handleLogout}
        sx={{
          backgroundColor: '#3f51b5',
          color: '#fff',
          '&:hover': { backgroundColor: '#303f9f' },
        }}
      >
        Iniciar sesión
      </Button>
      <AdBanner />

    </Box>
  );
};

export default Dashboard;
