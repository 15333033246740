import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle,  db, doc, getDoc } from '../../firebase-config';
import { useUser } from '../UserContext';
import './Login.css'; // Asegúrate de que los estilos sean consistentes

const Login = ({ onLogin, onFirstTimeLogin }) => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      const result = await signInWithGoogle();
      const user = result.user;
      
      setUser({ email: user.email, uid: user.uid });

      const businessExists = await checkIfBusinessExists(user.uid);

      onLogin();
      if (businessExists) {
        navigate('/movimientos');
      } else {
        navigate('/create-business');
        onFirstTimeLogin();
      }
    } catch (error) {
      // Maneja los errores específicos que quieras mostrar al usuario
      if (error.code === 'auth/popup-closed-by-user') {
        console.log('El usuario cerró la ventana emergente de autenticación.'); // Puedes mostrar un mensaje diferente si lo prefieres
      } else {
        setError('Ocurrió un error. Por favor, intenta nuevamente.');
      }
    } finally {
      setLoading(false);
    }
  };

  const checkIfBusinessExists = async (userId) => {
    try {
      const businessDocRef = doc(db, 'businesses', userId);
      const docSnap = await getDoc(businessDocRef);
      return docSnap.exists();
    } catch (error) {
      console.error("Error checking business data:", error);
      return false;
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <h1>¡Bienvenido de nuevo!</h1>
        <button onClick={handleGoogleSignIn} className="login-button" disabled={loading}>
          {loading ? 'Cargando...' : 'Iniciar sesión con Google'}
        </button>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
