import React, { useState, useEffect } from 'react';
import './Statistics.css'; // Archivo CSS para los estilos
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import NavBar from '../NavBar/navBar';
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../../firebase-config';

// Registrar los elementos necesarios
ChartJS.register(ArcElement, Tooltip, Legend);

const Statistics = () => {
  const [salesTotal, setSalesTotal] = useState(0);
  const [expensesTotal, setExpensesTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('amount'); // 'amount' o 'percentage'

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          // Fetch sales data
          const salesRef = collection(db, 'users', user.uid, 'productosVenta');
          const salesSnapshot = await getDocs(salesRef);
          let totalSales = 0;
          salesSnapshot.forEach(doc => {
            const data = doc.data();
            const precioVenta = Number(data.precioVenta) || 0;
            const cantidad = Number(data.cantidad) || 0;

            // Solo sumar si cantidad es mayor a 0
            if (cantidad > 0) {
              totalSales += precioVenta * cantidad;
            }
            
            // Debugging: Log each sale's contribution to the total
            console.log(`Venta: ${data.nombre}, Precio: ${precioVenta}, Cantidad: ${cantidad}, Total parcial: ${totalSales}`);
          });
          console.log(`Total Ventas: ${totalSales}`); // Debugging

          // Fetch expenses data
          const expensesRef = collection(db, 'users', user.uid, 'gastosCompras');
          const expensesSnapshot = await getDocs(expensesRef);
          let totalExpenses = 0;
          expensesSnapshot.forEach(doc => {
            const data = doc.data();
            totalExpenses += Number(data.amount) || 0;
          });
          console.log(`Total Gastos/Compras: ${totalExpenses}`); // Debugging

          setSalesTotal(totalSales);
          setExpensesTotal(totalExpenses);
        } catch (error) {
          console.error("Error fetching data: ", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  // Calculate totals for the chart
  const total = salesTotal + expensesTotal;

  const data = {
    labels: ['Ventas', 'Gastos y Compras'],
    datasets: [
      {
        data: viewMode === 'percentage'
          ? [salesTotal / total * 100, expensesTotal / total * 100]
          : [salesTotal, expensesTotal],
        backgroundColor: ['#4caf50', '#f44336'],
        hoverBackgroundColor: ['#66bb6a', '#e57373'],
      },
    ],
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="dashboard-container">
      <NavBar />
      <header>
        <h1>Estadísticas</h1>
        <div className="icons">
          <i className="icon-calendar"></i>
          <i className="icon-refresh"></i>
        </div>
      </header>

      {/* Botones para cambiar entre cantidad y porcentaje */}
      <div className="view-toggle">
        <button onClick={() => handleViewModeChange('amount')} className={viewMode === 'amount' ? 'active' : ''}>
          Cantidad
        </button>
        <button onClick={() => handleViewModeChange('percentage')} className={viewMode === 'percentage' ? 'active' : ''}>
          Porcentaje
        </button>
      </div>

      <div className="chart">
        <Pie data={data} style={{ width: '300px', height: '300px' }} />
      </div>

      <div className="report-section">
        <div className="report-card">
          <h2>Ventas [Total utilidad]</h2>
          <p className={`amount ${viewMode === 'amount' ? 'green' : ''}`}>
            {viewMode === 'percentage'
              ? `${(salesTotal / total * 100).toFixed(2)}%`
              : `${salesTotal.toFixed(2)} MXN`}
          </p>
        </div>

        <div className="report-card">
          <h2>Gastos y Compras</h2>
          <p className={`amount ${viewMode === 'percentage' ? 'red' : ''}`}>
            {viewMode === 'percentage'
              ? `${(expensesTotal / total * 100).toFixed(2)}%`
              : `${expensesTotal.toFixed(2)} MXN`}
          </p>
        </div>
      </div>

      {/* Footer para los íconos */}
      <footer>
        <i className="icon-doc"></i>
        <i className="icon-trash"></i>
        <i className="icon-chart"></i>
        <i className="icon-users"></i>
        <i className="icon-profile"></i>
      </footer>
    </div>
  );
};

export default Statistics;
