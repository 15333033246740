import React, { useState, useEffect } from "react";
import './Movements.css';
import NavBar from "../NavBar/navBar";
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase-config';

const Movements = () => {
  const [gastosCompras, setGastosCompras] = useState([]);
  const [ventas, setVentas] = useState([]);
  const [productosVenta, setProductosVenta] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      // Suscripción a la colección de gastos y compras
      const gastosCollection = collection(db, 'users', user.uid, 'gastosCompras');
      const gastosQuery = query(gastosCollection, orderBy('timestamp', 'desc'));
      const gastosUnsubscribe = onSnapshot(gastosQuery, snapshot => {
        const gastosList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
          typeColor: doc.data().typeColor || 'green',
          netTotal: Number(doc.data().costo) || 0,
          tipo: doc.data().tipo || 'Sin tipo'
        }));
        console.log("Gastos y Compras actualizados:", gastosList); // Agregar console log
        setGastosCompras(gastosList);
      });
  
      // Suscripción a la colección de ventas
      const ventasCollection = collection(db, 'users', user.uid, 'ventas');
      const ventasQuery = query(ventasCollection, orderBy('timestamp', 'desc'));
      const ventasUnsubscribe = onSnapshot(ventasQuery, snapshot => {
        const ventasList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
          typeColor: doc.data().typeColor || '#052796',
          netTotal: Number(doc.data().netTotal) || 0,
          tipo: doc.data().tipo || 'Sin tipo'
        }));
        console.log("Ventas actualizadas:", ventasList); // Agregar console log
        setVentas(ventasList);
      });
  
      // Suscripción a la colección de productos en venta
      const productosCollection = collection(db, 'users', user.uid, 'productosVenta');
      const productosQuery = query(productosCollection, orderBy('timestamp', 'desc'));
      const productosUnsubscribe = onSnapshot(productosQuery, snapshot => {
        const productosList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
          typeColor: doc.data().typeColor || '#052796',
          precioVenta: Number(doc.data().precioVenta) || 0,
          tipo: doc.data().tipo || 'Ventas',
          cantidad: Number(doc.data().cantidad) || 0
        }));
        console.log("Productos en Venta actualizados:", productosList); // Agregar console log
        setProductosVenta(productosList);
      });
  
      // Cleanup subscriptions on unmount
      return () => {
        gastosUnsubscribe();
        ventasUnsubscribe();
        productosUnsubscribe();
      };
    }
  }, []);
  
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleVentasClick = () => {
    navigate('/ventas');
    togglePopup();
  };

  const handleGastosClick = () => {
    navigate('/gastos');
    togglePopup();
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
  };

  const groupByDate = (items) => {
    return items.reduce((acc, item) => {
      const dateKey = formatDate(item.timestamp);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(item);
      return acc;
    }, {});
  };

  const groupedGastosCompras = groupByDate(gastosCompras);
  const groupedVentas = groupByDate(ventas);
  const groupedProductosVenta = groupByDate(productosVenta);

  const renderGroup = (group, type) => {
    return Object.entries(group).map(([date, items]) => {
      const totalExpenseAmount = items
        .filter(item => item.tipo === 'gasto' || item.tipo === 'compra')
        .reduce((sum, item) => sum + (item.netTotal || item.precioCosto || 0), 0);
  
      const totalSalesAmount = items
        .filter(item => item.tipo === 'venta')
        .reduce((sum, item) => sum + (item.netTotal || item.precioVenta || 0), 0);
  
      const totalSalesQuantity = items
        .filter(item => item.tipo === 'venta')
        .reduce((sum, item) => {
          if (item.products && item.products.length > 0) {
            return sum + item.products.reduce((productSum, product) => productSum + (product.cantidad || 0), 0);
          } else {
            return sum + (item.cantidad || 0);
          }
        }, 0);
  
      return (
        <div key={date} className="date-group">
          <h3>{date}</h3>
          {items.map(item => (
            <div 
              key={item.id} 
              className={`movement-card ${item.typeColor === '#052796' ? 'blue' : ''}`}
            >
              <p>{item.descripcion || 'Sin descripción'}</p>
              <p className="movement-amount">
                {item.netTotal ? item.netTotal.toFixed(2) : item.precioVenta ? item.precioVenta.toFixed(2) : '0.00'} MXN
              </p>
              <p>{formatTime(item.timestamp) || 'Sin hora'}</p>
              <div 
                className="movement-status" 
                style={{ backgroundColor: item.typeColor || 'transparent' }}
              >
                {item.tipo || 'Sin tipo'}
              </div>
            </div>
          ))}
          {totalSalesAmount > 0 && (
            <div className="totals">
              <p>Total Cantidades Vendidas: {totalSalesQuantity}</p>
              <p>Total Ventas: {totalSalesAmount.toFixed(2)} MXN</p>
            </div>
          )}
          {totalExpenseAmount > 0 && (
            <div className="totals">
              <p>Total Gastos/Compras: {totalExpenseAmount.toFixed(2)} MXN</p>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="container">
      <NavBar />
      <div className="movements-list">
        <h2>Gastos y Compras</h2>
        {renderGroup(groupedGastosCompras)}
        <h2>Ventas</h2>
        {renderGroup(groupedVentas)}
        <h2>Productos en Venta</h2>
        {renderGroup(groupedProductosVenta, 'productos')}
      </div>
      <div className="floatingButtons">
        <button className="btnAdd" onClick={togglePopup}>+</button>
      </div>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <div className="popup-header">
              <span>Selecciona una opción</span>
              <button className="close-btn" onClick={togglePopup}>X</button>
            </div>
            <div className="popup-options">
              <div className="option" onClick={handleVentasClick} style={{ cursor: 'pointer' }}>
                <h3>Ventas</h3>
                <p>Ventas de productos y/o servicios.</p>
              </div>
              <div className="option" onClick={handleGastosClick} style={{ cursor: 'pointer' }}>
                <h3>Gastos y/o Compras</h3>
                <p>Compras de productos, arriendo, servicios, entre otros.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Movements;
