import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, getDocs, writeBatch, setDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBuT9-0XopFn01mGbSPq0fBikt58V_XLsU",
  authDomain: "proyecto-id-b4380.firebaseapp.com",
  projectId: "proyecto-id-b4380",
  storageBucket: "proyecto-id-b4380.appspot.com",
  messagingSenderId: "186784120674",
  appId: "1:186784120674:web:2971ef87591b4cd462b63b",
  measurementId: "G-913X4EC880"
};

// Inicializamos Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Configuración de Google Auth Provider
const provider = new GoogleAuthProvider();

// Función para iniciar sesión con Google
const signInWithGoogle = () => signInWithPopup(auth, provider);

// Exportamos auth, db, storage y otras funcionalidades
export { auth, db, storage, signInWithEmailAndPassword, doc, getDoc, collection, getDocs, writeBatch, setDoc, deleteDoc, Timestamp, signInWithGoogle };
