import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateBusiness.css';
import { FaSave } from 'react-icons/fa';
import CurrencyModal from './CurrencyModal'; // Importamos el Modal

import { db } from '../../firebase-config'; // Asegúrate de importar correctamente tu configuración
import { doc, setDoc } from 'firebase/firestore'; // Importamos las funciones necesarias de Firestore
import { auth } from '../../firebase-config'; // Para obtener el UID del usuario

const countryCodes = [
  { code: '+52', country: 'México' },
  { code: '+1', country: 'Estados Unidos/Canadá' },
  { code: '+54', country: 'Argentina' },
  { code: '+55', country: 'Brasil' },
  { code: '+56', country: 'Chile' },
  { code: '+57', country: 'Colombia' },
  { code: '+58', country: 'Venezuela' },
  { code: '+44', country: 'Reino Unido' },
  { code: '+33', country: 'Francia' },
  { code: '+49', country: 'Alemania' },
  { code: '+34', country: 'España' },
  { code: '+351', country: 'Portugal' },
  // Agregar más códigos de área según sea necesario
];

const CreateBusiness = () => {
  const [businessName, setBusinessName] = useState('');
  const [currency, setCurrency] = useState('Seleccione Moneda');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+52'); // Estado para la LADA
  const [secondPhoneNumber, setSecondPhoneNumber] = useState('');
  const [secondCountryCode, setSecondCountryCode] = useState('+52');
  const [website, setWebsite] = useState('');
  const [address, setAddress] = useState('');
  const [image, setImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el Modal
  const navigate = useNavigate();

  useEffect(() => {
    const savedBusinessData = localStorage.getItem('businessData');
    if (savedBusinessData) {
      const {
        businessName,
        currency,
        phoneNumber,
        secondPhoneNumber,
        website,
        address,
        image
      } = JSON.parse(savedBusinessData);

      setBusinessName(businessName);
      setCurrency(currency);
      setPhoneNumber(phoneNumber.replace(countryCode, '').trim()); // Ajusta según sea necesario
      setSecondPhoneNumber(secondPhoneNumber.replace(secondCountryCode, '').trim());
      setWebsite(website);
      setAddress(address);
      setImage(image);
    }
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const blobUrl = URL.createObjectURL(file); // Crea una URL de blob para la imagen
      setImage(blobUrl);
    }
  };
  

  const handleSave = async (e) => {
    e.preventDefault();
  
    try {
      // Obtenemos el UID del usuario actual
      const user = auth.currentUser;
      if (!user) {
        throw new Error('Usuario no autenticado');
      }
  
      const uid = user.uid; // El UID del usuario actual
  
      // Crear un objeto con todos los datos del formulario
      const businessData = {
        businessName,
        currency,
        phoneNumber: `${countryCode} ${phoneNumber}`,
        secondPhoneNumber: `${secondCountryCode} ${secondPhoneNumber}`,
        website,
        address,
        image
      };
  
      // Guardar los datos en Firestore, dentro de una colección 'businesses' usando el UID del usuario
      await setDoc(doc(db, 'businesses', uid), businessData);
  
      // Redirigir a la página de resumen
      navigate('/business-overview');
    } catch (error) {
      console.error("Error al guardar los datos del negocio:", error);
    }
  };
  

  const handleCurrencySelect = (currency) => {
    setCurrency(`${currency.flag} ${currency.code}`);
  };

  return (
    <div className="create-business-container">
      <header className="header">
        <h1>Crear Negocio</h1>
        <button className="save-button" onClick={handleSave}>
          <FaSave /> Guardar
        </button>
      </header>

      <form onSubmit={handleSave} className="create-business-form">
        <div className="form-group">
          <label htmlFor="businessName">Nombre del Negocio</label>
          <input
            type="text"
            id="businessName"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="currencyFormat">Formato de Moneda</label>
          <input
            type="text"
            id="currencyFormat"
            value={currency}
            readOnly
            onClick={() => setIsModalOpen(true)} // Abre el modal al hacer clic
          />
        </div>

        {/* Primer número de teléfono con selección de LADA */}
        <div className="form-group">
          <label htmlFor="phoneNumber">Número de Teléfono</label>
          <div className="phone-group">
            <select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="country-code-select"
            >
              {countryCodes.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.country} ({country.code})
                </option>
              ))}
            </select>
            <input
              type="tel"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Ingrese su número"
              required
            />
          </div>
        </div>

        {/* Segundo número de teléfono con selección de LADA */}
        <div className="form-group">
          <label htmlFor="secondPhoneNumber">Segundo Número de Teléfono</label>
          <div className="phone-group">
            <select
              value={secondCountryCode}
              onChange={(e) => setSecondCountryCode(e.target.value)}
              className="country-code-select"
            >
              {countryCodes.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.country} ({country.code})
                </option>
              ))}
            </select>
            <input
              type="tel"
              id="secondPhoneNumber"
              value={secondPhoneNumber}
              onChange={(e) => setSecondPhoneNumber(e.target.value)}
              placeholder="Ingrese su segundo número"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="website">Sitio Web (Opcional)</label>
          <input
            type="url"
            id="website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="address">Dirección (Opcional)</label>
          <textarea
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></textarea>
        </div>

        <div className="form-group">
          <label>Subir Imagen</label>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          {image && <img src={image} alt="Business Logo" className="business-logo" />}
        </div>

        <button type="submit" className="submit-button">
          <FaSave /> Guardar Negocio
        </button>
      </form>

      {/* Renderizamos el modal */}
      <CurrencyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCurrencySelect={handleCurrencySelect}
      />
    </div>
  );
};

export default CreateBusiness;
