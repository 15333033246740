import React, { useState } from 'react';
import './PageView.css'; // Importa tu archivo CSS para estilos

const PageView = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      title: 'La forma más fácil de administrar tu negocio',
      description: 'Descubre cómo puedes gestionar tu negocio de manera eficiente y sencilla.',
      body: 'Aquí tienes información sobre cómo nuestra solución puede ayudarte a administrar tu negocio con facilidad.',

    },
    {
      title: 'Optimiza tu tiempo y esfuerzo',
      description: 'Utiliza herramientas efectivas para ahorrar tiempo y mejorar la productividad.',
      body: 'Conoce cómo nuestras herramientas pueden hacer que la gestión de tu negocio sea más eficiente y menos estresante.',
    },
    {
      title: 'Simplifica la administración de tu negocio',
      description: 'Obtén acceso a funcionalidades avanzadas que facilitan la administración de tu empresa.',
      body: 'Aprende más sobre nuestras características y cómo pueden simplificar la administración de tu negocio.',
    },
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAction = () => {
    if (currentStep === steps.length - 1) {
      // Lógica para "Crear Cuenta" o "Iniciar Sesión"
      window.location.href = '/login'; // Cambia la URL según sea necesario
    }
  };

  return (
    <div className="page-view">
      <div className="slider-container">
        <div
          className="slider"
          style={{ transform: `translateX(-${currentStep * 100}%)` }}
        >
          {steps.map((step, index) => (
            <div className="step-content" key={index}>
              <h1>{step.title}</h1>
              <p className="description">{step.description}</p>
              <p className="body-text">{step.body}</p>
              <img src={step.image} alt={`Step ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
      <div className="buttons">
        {currentStep < steps.length - 1 && (
          <button className="prev-btn" onClick={handlePrevious}>← Anterior</button>
        )}
        <button className="next-btn" onClick={currentStep < steps.length - 1 ? handleNext : handleAction}>
          {currentStep < steps.length - 1 ? 'Siguiente →' : 'Crear Cuenta / Iniciar Sesión'}
        </button>
      </div>
      <div className="pagination-dots">
        {steps.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentStep === index ? 'active' : ''}`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default PageView;
