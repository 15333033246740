// src/components/BusinessOverview/BusinessOverview.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../firebase-config'; // Asegúrate de que la ruta sea correcta
import './BusinessOverview.css'; // Importa el archivo CSS
import NavBar from '../NavBar/navBar';

const BusinessOverview = () => {
  const [businessData, setBusinessData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessData = async () => {
      if (auth.currentUser) {
        try {
          const userId = auth.currentUser.uid;
          const businessRef = doc(db, 'businesses', userId); // Reemplaza 'businesses' con el nombre de tu colección
          const docSnap = await getDoc(businessRef);

          if (docSnap.exists()) {
            setBusinessData(docSnap.data());
          } else {
            navigate('/create-business'); // Redirige al formulario si no hay datos
          }
        } catch (error) {
          console.error('Error fetching business data:', error);
          navigate('/create-business'); // Redirige en caso de error
        }
      } else {
        navigate('/login'); // Redirige si el usuario no está autenticado
      }
    };

    fetchBusinessData();
  }, [navigate]);

  if (!businessData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="business-overview-container">
      <NavBar />
      <h1>Resumen del Negocio</h1>
      <div className="business-overview-content">
        <h2>{businessData.businessName}</h2>
        <p><strong>Moneda:</strong> {businessData.currency}</p>
        <p><strong>Teléfono:</strong> {businessData.phoneNumber}</p>
        <p><strong>Segundo Teléfono:</strong> {businessData.secondPhoneNumber}</p>
        <p><strong>Sitio Web:</strong> {businessData.website}</p>
        <p><strong>Dirección:</strong> {businessData.address}</p>
        {businessData.image && <img src={businessData.image} alt="Business Logo" className="business-logo" />}
      </div>
    </div>
  );
};

export default BusinessOverview;
