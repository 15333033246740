import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Grid, Button, TextField, Modal, MenuItem } from '@mui/material';
import { collection, getDocs, setDoc, doc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase-config';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate

const NuevoGastoCompra = () => {
  const [proveedor, setProveedor] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [tipo, setTipo] = useState('');
  const [formaPago, setFormaPago] = useState('');
  const [costo, setCosto] = useState('');
  const [proveedores, setProveedores] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate(); // Inicializar useNavigate

  // Cargar proveedores desde Firebase
  useEffect(() => {
    const fetchProveedores = async () => {
      const user = auth.currentUser;
      if (user) {
        const proveedoresCollection = collection(db, 'users', user.uid, 'proveedores');
        const proveedoresSnapshot = await getDocs(proveedoresCollection);
        const proveedoresList = proveedoresSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProveedores(proveedoresList);
      }
    };

    fetchProveedores();
  }, []);

  const handleProveedorSelect = (selectedProveedor) => {
    setProveedor(`${selectedProveedor.name} - ${selectedProveedor.phone}`);
    setOpenModal(false); // Cerrar modal después de seleccionar
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      try {
        await setDoc(doc(db, 'users', user.uid, 'gastosCompras', new Date().toISOString()), {
          proveedor,
          descripcion,
          tipo,
          formaPago,
          costo,
          timestamp: Timestamp.now(), // Añadir timestamp
          amount: parseFloat(costo), // Convertir el costo a número
          typeColor: tipo === 'gasto' ? 'red' : tipo === 'producto' ? 'yellow' : 'default', // Color asociado
        });
        // Redirigir a la página anterior después de guardar
        navigate(-1); // Volver a la página anterior
      } catch (error) {
        console.error("Error al guardar el documento: ", error);
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#000',
        color: '#fff',
        height: '100vh',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Header */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Nuevo Gasto / Compra
        </Typography>
        <Divider sx={{ backgroundColor: '#444' }} />
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          {/* Selecciona un tipo */}
          <Grid item xs={12}>
            <Typography color="#ff5252">Selecciona un tipo</Typography>
            <TextField
              fullWidth
              select
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
              variant="outlined"
              sx={{ backgroundColor: '#333', color: '#fff' }}
              InputProps={{
                readOnly: false,
              }}
            >
              <MenuItem value="gasto">Gasto</MenuItem>
              <MenuItem value="producto">Producto</MenuItem>
            </TextField>
            {tipo === 'gasto' && (
              <Typography variant="body2" color="#ddd">
                Todos los gastos de oficina, arriendos, servicios, etc.
              </Typography>
            )}
            {tipo === 'producto' && (
              <Typography variant="body2" color="#ddd">
                Productos comprados y/o elaborados para la venta.
              </Typography>
            )}
          </Grid>

          {/* Forma de pago */}
          <Grid item xs={12}>
            <Typography color="#ff5252">Forma de pago</Typography>
            <TextField
              fullWidth
              select
              value={formaPago}
              onChange={(e) => setFormaPago(e.target.value)}
              variant="outlined"
              sx={{ backgroundColor: '#333', color: '#fff' }}
            >
              <MenuItem value="efectivo">Efectivo</MenuItem>
              <MenuItem value="transferencia">Transferencia Bancaria</MenuItem>
              <MenuItem value="tarjeta">Tarjeta Crédito/Débito</MenuItem>
              <MenuItem value="credito">A Crédito</MenuItem>
            </TextField>
          </Grid>

          {/* Costo total */}
          <Grid item xs={12}>
            <Typography color="#ff5252">Costo total</Typography>
            <TextField
              fullWidth
              value={costo}
              onChange={(e) => setCosto(e.target.value)}
              variant="outlined"
              placeholder="Monto"
              sx={{ backgroundColor: '#333', color: '#fff' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color="red">Buscar proveedor (Opcional)</Typography>
            <TextField
              fullWidth
              value={proveedor}
              onClick={() => setOpenModal(true)} // Abrir modal al hacer clic
              variant="outlined"
              sx={{ backgroundColor: '#333', color: '#fff' }}
              InputProps={{
                readOnly: true,
              }}
              placeholder="Sin seleccionar"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color="red">Descripción</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              variant="outlined"
              placeholder="Puedes agregar el número de tu factura, los productos, etc"
              sx={{ backgroundColor: '#333', color: '#fff' }}
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Modal para seleccionar proveedor */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: '#222',
            color: '#fff',
            padding: 4,
            borderRadius: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: '10px', color: '#ff5252' }}>
            Selecciona un proveedor
          </Typography>
          {proveedores.map((prov) => (
            <Box
              key={prov.id}
              onClick={() => handleProveedorSelect(prov)}
              sx={{
                padding: 2,
                cursor: 'pointer',
                backgroundColor: '#333',
                marginBottom: '10px',
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: '#444',
                },
              }}
            >
              <strong>{prov.name}</strong>
            </Box>
          ))}
          <Button
            onClick={() => setOpenModal(false)}
            sx={{
              mt: 2,
              backgroundColor: 'red',
              color: '#fff',
              '&:hover': { backgroundColor: '#d32f2f' },
            }}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>

      {/* Botón para guardar */}
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            backgroundColor: 'red',
            color: '#fff',
            '&:hover': { backgroundColor: '#d32f2f' },
          }}
        >
          Guardar
        </Button>
      </Box>
    </Box>
  );
};

export default NuevoGastoCompra;
