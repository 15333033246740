import React, { useEffect, useState } from 'react';
import './Product.css';
import NavBar from "../NavBar/navBar";
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../firebase-config';

const ProductsAndServices = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCost, setTotalCost] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          const productsRef = collection(db, 'users', user.uid, 'productosVenta');
          const snapshot = await getDocs(productsRef);
          const productsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          setProducts(productsList);

          const cost = productsList.reduce((sum, product) => sum + (product.precioCosto * product.cantidad), 0);
          const sale = productsList.reduce((sum, product) => sum + (product.precioVenta * product.cantidad), 0);

          setTotalCost(cost);
          setTotalSale(sale);
        } catch (error) {
          console.error("Error fetching products: ", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProducts();
  }, []);

  const handleAddProduct = () => {
    navigate('/nuevo-producto');
  };

  const handleMoney = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setUpdatedProduct(product);
    setEditModalOpen(true);
  };

  const handleDeleteProduct = async (productId) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const productRef = doc(db, 'users', user.uid, 'productosVenta', productId);
        await deleteDoc(productRef);
        setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
        alert("Producto eliminado exitosamente.");
      } catch (error) {
        console.error("Error al eliminar el producto: ", error);
        alert("Ocurrió un error al intentar eliminar el producto.");
      }
    }
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSaveProduct = async () => {
    const user = auth.currentUser;
    if (user && selectedProduct) {
      try {
        const productRef = doc(db, 'users', user.uid, 'productosVenta', selectedProduct.id);
        await updateDoc(productRef, updatedProduct);
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === selectedProduct.id ? { ...product, ...updatedProduct } : product
          )
        );
        alert("Producto actualizado exitosamente.");
        setEditModalOpen(false);
      } catch (error) {
        console.error("Error al actualizar el producto: ", error);
        alert("Ocurrió un error al intentar actualizar el producto.");
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <div>
        <NavBar />
      </div>
      <header className="header">
        <h2>Mis productos y servicios</h2>
      </header>

      <div className="product-list">
        {products.length > 0 ? (
          products.map(product => (
            <div key={product.id} className="product-card">
              <div className="product-info">
                <img src={product.imageUrl || 'placeholder.png'} alt={product.nombre} className="product-image" />
                <div>
                  <h3>{product.nombre}</h3>
                  <p>Código: {product.id}</p>
                  <p>P.V: {product.precioVenta.toFixed(2)} MXN</p>
                </div>
              </div>
              <div className="product-quantity">
                <p>{product.cantidad} {product.unidad}</p>
              </div>

              <div className="product-actions">
                <button onClick={() => handleEditProduct(product)}>Editar</button>
                <button onClick={() => handleDeleteProduct(product.id)}>Eliminar</button>
              </div>
            </div>
          ))
        ) : (
          <Typography variant="h6" component="p">
            No hay productos disponibles.
          </Typography>
        )}
      </div>

      <div className="floating-buttons">
        <Button
          className="btn-money"
          onClick={handleMoney}
          sx={{
            backgroundColor: '#ff5722',
            color: 'white',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            fontSize: '1.5em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
          }}
        >
          💰
        </Button>
        <Button
          className="btn-add"
          onClick={handleAddProduct}
          sx={{
            backgroundColor: '#ff5722',
            color: 'white',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            fontSize: '1.5em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
          }}
        >
          ➕
        </Button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Suma Total
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2,color: 'black'}}>
            Productos en Inventario por precio del costo: {totalCost.toFixed(2)} MXN
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2,color: 'black' }}>
            Productos en Inventario por precio de venta: {totalSale.toFixed(2)} MXN
          </Typography>

          <Button onClick={handleClose} sx={{ marginTop: '20px', color: 'red' }}>
            Cerrar
          </Button>
        </Box>
      </Modal>

      {selectedProduct && (
        <Modal
          open={editModalOpen}
          onClose={handleEditModalClose}
          aria-labelledby="edit-modal-title"
          aria-describedby="edit-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '10px',
              boxShadow: 24,
              p: 4,
              textAlign: 'center',
            }}
          >
            <Typography id="edit-modal-title" variant="h6" component="h2">
              Editar Producto
            </Typography>

            <TextField
              label="Nombre"
              name="nombre"
              value={updatedProduct.nombre || ''}
              onChange={handleEditChange}
              fullWidth
              sx={{ my: 2 }}
            />
            <TextField
              label="Precio de Venta"
              name="precioVenta"
              value={updatedProduct.precioVenta || ''}
              onChange={handleEditChange}
              fullWidth
              sx={{ my: 2 }}
            />
            <TextField
              label="Cantidad"
              name="cantidad"
              value={updatedProduct.cantidad || ''}
              onChange={handleEditChange}
              fullWidth
              sx={{ my: 2 }}
            />

            <Button onClick={handleSaveProduct} sx={{ marginTop: '20px' }}>
              Guardar
            </Button>
            <Button onClick={handleEditModalClose} sx={{ marginTop: '20px', color: 'red' }}>
              Cerrar
            </Button>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ProductsAndServices;
